
export function maxHeightContent(targets = document.querySelectorAll(".js-maxHeightContent > *")){
    function generateheightContentButtonWrapper(target){
        let buttonTextExpand = "read more";
        let buttonTextCollapse = "fold in";

        if(target.dataset.buttonFoldout){
            buttonTextExpand = target.dataset.buttonFoldout;
            buttonTextCollapse = target.dataset.buttonFoldin;
        }

        if(target.dataset.buttonFoldin){
            buttonTextCollapse = target.dataset.buttonFoldin;
        }

        const newButtonWrapper = document.createElement("div");
        newButtonWrapper.classList.add("maxHeightContent__buttonWrapper");

        const newButton = document.createElement("a");
        newButton.classList.add("maxHeightContent__button");
        newButton.innerText = buttonTextExpand;
        newButton.setAttribute("href","javascript:void(0)");

        newButtonWrapper.appendChild(newButton);

        newButton.addEventListener("click",function (){
            if(!target.classList.contains("is-open")){
                target.classList.add("is-open");
                newButton.innerText = buttonTextCollapse;
            }else{
                target.classList.remove("is-open");
                newButton.innerText = buttonTextExpand;
            }
            window.scroll({
                top: target.getBoundingClientRect().top + window.scrollY,
                behavior: 'smooth'
            });
        });

        return newButtonWrapper;
    }

    const ro = new ResizeObserver( entries => {
        for (let entry of entries) {
            const cr = entry.contentRect;
            const target = entry.target;
            const parentTarget = target.closest(".js-maxHeightContent");
            if(parentTarget){
                if(cr.height > parentTarget.clientHeight){
                    parentTarget.classList.add("is-active");
                    if(!parentTarget.querySelector(".maxHeightContent__button")){
                        parentTarget.appendChild(generateheightContentButtonWrapper(parentTarget));
                    }
                }
            }
        }
    });

    for(let el of targets){
        ro.observe(el);
    }
}