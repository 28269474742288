function fillColor(slider){
    let percent1 = (slider.sliderMin.value / slider.sliderMaxValue) * 100;
    let percent2 = (slider.sliderMax.value / slider.sliderMaxValue) * 100;
    slider.sliderTrack.style.background = `linear-gradient(to right, var(--main-color-middle-gray) ${percent1}% , var(--main-color-black) ${percent1}% , var(--main-color-black) ${percent2}%, var(--main-color-middle-gray) ${percent2}%)`;
}

function updateSliderValue(slider){
    slider.sliderValue.value = `[${slider.sliderMin.value},${slider.sliderMax.value}]`;
}

function slideMin(slider){
    if((parseInt(slider.sliderMax.value) - parseInt(slider.sliderMin.value) <= parseInt(slider.minGap)) && parseInt(slider.minGap) > 0){
        slider.sliderMin.value = parseInt(slider.sliderMax.value) - parseInt(slider.minGap);
    }
    slider.inputMin.value = slider.sliderMin.value;
    updateSliderValue(slider);
    fillColor(slider);
}

function slideMax(slider){
    if((parseInt(slider.sliderMax.value) - parseInt(slider.sliderMin.value) <= parseInt(slider.minGap)) && parseInt(slider.minGap) > 0){
        slider.sliderMax.value = parseInt(slider.sliderMin.value) + parseInt(slider.minGap);
    }
    slider.inputMax.value = slider.sliderMax.value;
    updateSliderValue(slider);
    fillColor(slider);
}

function inputMin(slider) {
    if ((parseInt(slider.inputMin.value) + parseInt(slider.minGap) > parseInt(slider.inputMax.value)) && parseInt(slider.minGap) > 0) {
        slider.inputMin.value = parseInt(slider.inputMax.value) - parseInt(slider.minGap);
    }
    slider.sliderMin.value = slider.inputMin.value;
    updateSliderValue(slider);
    fillColor(slider);
}

function checkInputMinValue(slider) {
    if(slider.inputMin.value === "" || (parseInt(slider.inputMin.value) <= parseInt(slider.inputMin.getAttribute("min")))){
        slider.inputMin.value = slider.inputMin.getAttribute("min");
    }
    updateSliderValue(slider);
    fillColor(slider);
}

function inputMax(slider) {
    if ((parseInt(slider.inputMax.value) - parseInt(slider.minGap) < parseInt(slider.inputMin.value)) && parseInt(slider.minGap) > 0) {
        slider.inputMax.value = parseInt(slider.inputMin.value) + parseInt(slider.minGap);
    }
    slider.sliderMax.value = slider.inputMax.value;
    updateSliderValue(slider);
    fillColor(slider);
}

function checkInputMaxValue(slider) {
    if(slider.inputMax.value === "" || parseInt(slider.inputMax.value) >= parseInt(slider.inputMax.getAttribute("max"))){
        slider.inputMax.value = slider.inputMax.getAttribute("max");
    }
    slider.sliderMax.value = slider.inputMax.value;
    updateSliderValue(slider);
    fillColor(slider);
}

export function initRangeslider(wrapper = document,callback = function (){}) {
    for (let slider of wrapper.querySelectorAll('.js-rangeSlider')) {
        try {
            slider.sliderMin = slider.querySelector(".js-rangeSliderRangeMin");
            slider.sliderMax = slider.querySelector(".js-rangeSliderRangeMax");
            slider.inputMin = slider.querySelector(".js-rangeSliderInputMin");
            slider.inputMax = slider.querySelector(".js-rangeSliderInputMax");
            slider.sliderTrack = slider.querySelector(".js-rangeSliderTrack");
            slider.sliderValue = slider.querySelector(".js-rangeSliderRangeValue");
            slider.sliderMaxValue = slider.inputMin.max;
            slider.minGap = 0;

            slideMin(slider);
            slideMax(slider);

            slider.sliderMin.addEventListener("input",function (){
                slideMin(slider);
                callback({
                    slider: slider,
                    value: slider.sliderValue.value
                })
            });

            slider.sliderMax.addEventListener("input",function (){
                slideMax(slider);
                callback({
                    slider: slider,
                    value: slider.sliderValue.value
                })
            });

            slider.inputMin.addEventListener("keyup",function (){
                inputMin(slider);
                callback({
                    slider: slider,
                    value: slider.sliderValue.value
                })
            });

            slider.inputMin.addEventListener("blur",function (){
                checkInputMinValue(slider);
                callback({
                    slider: slider,
                    value: slider.sliderValue.value
                })
            });

            slider.inputMax.addEventListener("keyup",function (){
                inputMax(slider);
                callback({
                    slider: slider,
                    value: slider.sliderValue.value
                })
            });

            slider.inputMax.addEventListener("blur",function (){
                checkInputMaxValue(slider);
                callback({
                    slider: slider,
                    value: slider.sliderValue.value
                })
            });
        }catch (e){
            console.error(e);
        }
    }
}